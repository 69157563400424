
	frappe.templates['list_sidebar'] = `<div class="sidebar-section user-actions hide">
</div>
<div class="sidebar-section views-section hide">
	<div class="sidebar-label">
	</div>
	<div class="current-view">
		<div class="list-link">
			<a class="btn btn-default btn-sm list-sidebar-button"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				href="#"
			>
				<span class="selected-view ellipsis">
				</span>
				<span>
					<svg class="icon icon-xs">
						<use href="#icon-select"></use>
					</svg>
				</span>
			</a>
			<ul class="dropdown-menu views-dropdown" role="menu">
			</ul>
		</div>
		<div class="sidebar-action">
			<a class="view-action"></a>
		</div>
	</div>
</div>

<div class="sidebar-section filter-section">
	<div class="sidebar-label">
		<svg class="es-icon es-line icon-xs" aria-hidden="true">
			<use class="" href="#es-line-down"></use>
		</svg>
		{{ __("Filter By") }}
	</div>

	<div class="list-group-by">
	</div>
</div>

<div class="sidebar-section tags-section">
	<div class="sidebar-label">
		<svg class="es-icon es-line icon-xs" aria-hidden="true">
			<use class="" href="#es-line-right-chevron"></use>
		</svg>
		<span>{{ __("Tags") }}</span>
	</div>

	<div class="list-tags hide">
		<div class="list-stats list-link">
			<a
				class="btn btn-default btn-sm list-sidebar-button"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				href="#"
			>
				<span>{{ __("Tags") }}</span>
				<span>
					<svg class="icon icon-xs">
						<use href="#icon-select"></use>
					</svg>
				</span>
			</a>
			<ul class="dropdown-menu list-stats-dropdown" role="menu">
				<div class="dropdown-search">
					<input type="text" placeholder={{__("Search") }} data-element="search" class="form-control input-xs">
				</div>
				<div class="stat-result">
				</div>
			</ul>
		</div>
		<div class="sidebar-label">
			<div class="sidebar-action show-tags">
				<a class="list-tag-preview">{{ __("Show Tags") }}</a>
			</div>
		</div>
	</div>
</div>

<div class="sidebar-section save-filter-section">
	<div class="sidebar-label">
		<svg class="es-icon es-line icon-xs" aria-hidden="true">
			<use class="" href="#es-line-right-chevron"></use>
		</svg>
		<span>{{ __("Saved Filters") }}</span>
	</div>
	<div class="list-filters list-link hide"></div>
</div>
`;
